/* =======================================
 * Email address (de)obfuscation
 * ======================================= */
// @decalin
const address = ">2CE:;?o5642=:?]36"

function rot47(x) {
  let s = []

  for (let i = 0; i < x.length; i++) {
    var j = x.charCodeAt(i)
    if (j >= 33 && j <= 126) {
      s[i] = String.fromCharCode(33 + ((j + 14) % 94))
    } else {
      s[i] = String.fromCharCode(j)
    }
  }

  return s.join("")
}

document.querySelectorAll("[data-mailto]").forEach(function (el) {
  el.href = "mailto:" + rot47(address)
})

document.querySelectorAll("[data-text]").forEach(function (el) {
  el.innerText = rot47(address)
})

/* =======================================
 * Tooltip
 * ======================================= */
import { computePosition, flip, offset, shift } from "@floating-ui/dom"

document.querySelectorAll("abbr[aria-label]").forEach((element) => {
  const tooltip = document.createElement("span")
  tooltip.classList.add("tooltip")
  tooltip.innerHTML = element.getAttribute("aria-label")

  element.parentNode.insertBefore(tooltip, element)

  const show = () => {
    tooltip.style.display = "inline-block"

    computePosition(element, tooltip, {
      placement: "bottom-start",
      middleware: [offset(6), flip(), shift({ padding: 5 })],
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      })
    })

    document.addEventListener("touchstart", () => element.blur(), {
      once: true,
    })
  }

  const hide = () => {
    tooltip.style.display = ""
  }

  ;[
    ["mouseenter", show],
    ["mouseleave", hide],
    ["focus", show],
    ["blur", hide],
  ].forEach(([event, listener]) => {
    element.addEventListener(event, listener)
  })
})
